.custom-input-field{
    background: var(--pinkShade) !important;
    border-radius: 7px;
    height: 55px;
    width: 100%;
    font-size: 15px;
    border: none;
}
.field-icon{
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);    
}

.left-icon{
    left: 12px;
}
.right-icon{
    right: 12px;
}

.custom-input-field::placeholder{
    color: var(--grey-60);
    opacity: .7;
    font-size: 15px;
}

.custom-input-field:active, .custom-input-field:focus{
    outline: none;
    border: none;
}

.react-datepicker-ignore-onclickoutside, .react-datepicker-wrapper, .react-datepicker__input-container, .react-time-picker__wrapper{
    background: var(--pinkShade) !important;
    border-radius: 7px !important;
    height: 55px !important;
    width: 100%;
    font-size: 15px !important;
    border: none !important;
    padding: 0 10px;
}

.react-time-picker__inputGroup__input{
    border: none !important;
    border-radius: 7px !important;
    outline: none !important;
}

.react-datepicker-ignore-onclickoutside:active, .react-datepicker-ignore-onclickoutside:focus, .react-time-picker__inputGroup__input:focus{
    outline: none;
    border: none;
}