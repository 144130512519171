.search-bar{
    box-shadow: 0px 1px 56px #00000026;
    padding: 35px;
    border-radius: 10px;
}

.search-bar .search-input{
    width: 100% !important;
    /* height: 60px; */
    background: transparent;
    padding: 0px 0px 8px 0px;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--darkGray);
}

.search-bar .search-input::placeholder{
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 0.6px;
    color: var(--darkGray);
    opacity: .75;
}

.search-bar .search-bar-icon{
    position: absolute;
    top: 40%;
    right: 32px;
    transform: translateY(-40%);
    font-size: 27px;
    cursor: pointer;
}