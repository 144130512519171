.create-nft-section .art-layer-1{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: rgba(129, 138, 145, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.create-nft-section .art-layer-1 .art-layer-2{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--lightPurple);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-nft-section .art-layer-1 .art-layer-2 .art-layer-3{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-nft-section .art-text{
    border-right: none !important;
    border-width: 3px;
    border-style: solid;
    padding-left: 16px;
}

.digital-art-lines::after{
    content: "";
    position: absolute;
    height: 2px;  
    background: var(--grey);
    width: 89%;
    left: 111px;
    right: 0;
    top: 47px;
    z-index: 1;
}

@media screen and (max-width: 991px) {
    .digital-art-lines::after{
        display: none !important;
    }
}