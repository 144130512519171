.upload-file-nft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    color: #9B9B9D;
    object-fit: fill;
}

.upload-file-nft > span:nth-child(1){
    margin-bottom: 20px;
}

.upload-file-nft > input[type="file"]{
    display: none;
}

.submit-nft-fields{
    width: 80%;
    height: 45px;
    border-radius: 3px;
    padding: 0px 20px;
    font-size: 15px;
}

#edit-collection-dropdown1, #edit-collection-dropdown2{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
    background: var(--pinkShade4);
    padding: 20px 16px !important;
    height: 66px;
    border-radius: 4px;
    border: none !important;
    outline: none !important;
    font-size: 19px;
}

#edit-collection-dropdown1::after, #edit-collection-dropdown2::after{
    margin-top: 10px !important;
    font-size: 25px;
}

@media screen and (min-width: 1199px) {
    .left-side-inner-space{
        padding-right: 65px !important;
    }
}

@media screen and (max-width: 991px) {
    .submit-nft-fields{
        width: 100%;
    }
}

@media screen and (max-width: 767px){
    .upload-file-nft{
        height: 300px;
    }
}