.create-nft-section .slider-icons{
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.create-nft-section .nft-item__wrapper{
    padding: 0px 50px;
}

.create-nft-section .slider-icons:first-child{
    left: -20px;
}

.create-nft-section .slider-icons:last-child{
    right: -20px;
}

.create-nft-section .nft-item{
    margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
    .create-nft-section .nft-item{
        margin-bottom: 30px;
    }   
    .create-nft-section .nft-item__third{
        margin-top: 35px !important;
    }
    .create-nft-section .nft-item__wrapper{
        padding: 0px 10px;
    }
}