.product-detail-show{
    width: 75%;
}

/* .product-detail-padding{
    padding: 0px 65px;
} */

.action-popup-height{
    height: 220px;
}

.art-detail-img{
    width: 74% !important;
    margin: 0 auto !important;
}

.art-detail-wrapper{
    padding-top: 115px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .product-detail-show{
        width: 90%;
    }
    .product-detail-padding{
        padding: 0px 35px;
    }
    .action-popup-height{
        height: 150px;
    }
}

@media screen and (max-width: 767px) {
    .art-detail-wrapper{
        padding-top: 80px;
    }
    .product-detail-show{
        width: 100%;
    }
    .product-detail-padding{
        padding: 0px 10px;
    }

    .action-popup-height{
        height: auto;
        padding: 20px 0px;
    }
    .art-detail-img{
        width: 100% !important;
    }
}