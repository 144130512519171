.page-container{
    padding-top: 185px;
    padding-bottom: 0px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
}

@media screen and (max-width: 767px) {
    .page-container{
        padding-top: 120px;
        padding-bottom: 0px;
    }
}