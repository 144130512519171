.padding-section {
  padding: 15px 70px 15px 70px !important;
  transition: all 0.4s ease-in-out;
}

.app-logo{
  width: 180px;
}

.navbar-collapse {
  justify-content: end !important;
}

.toggleSwitch {
  margin: 1em 0em;
}

.navbar-nav {
  margin-left: 0em;
}

.navbar-toggler,
.navbar-toggler-icon {
  color: var(--white) !important;
}

.nav-links {
  width: auto;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  background: transparent !important;
  margin-right: 37px !important;
  color: inherit;
  line-height: 46px;
}

.nav-links:hover {
  text-decoration: none !important;
}

.active-link {
  border-radius: 2px;
  border-bottom: 2px solid var(--whiteSmoke20) !important;
}

.show-mobile-nav, .mobile-nav-menu{
  display: none;
}

@media screen and (min-width: 992px) {
  /* .nav-links {
    margin-right: 30px;
  } */
  .toggleSwitch {
    margin: 0em 0em 0em 0em;
  }
}

@media screen and (min-width: 1168px) and (max-width: 1260px){
  .nav-links {
    margin-right: 23px !important;
  }
}

@media screen and (min-width: 991px) and (max-width: 1167px){
  .padding-section {
    padding: 15px 30px 15px 30px !important;
  }

  .nav-links {
    margin-right: 15px !important;
  }

  .app-logo{
    width: 170px;
  }
}

@media screen and (max-width: 991px) {
  .padding-section {
    padding: 10px 20px 10px 20px !important;
  }
  .navbar-nav {
    margin: 0 !important;
  }
  .profile-btn {
    margin: 15px 0px;
  }
  .signin-out-btn {
    margin: 0 !important;
    padding: 13px 0px !important;
  }
  .show-mobile-nav{
    display: flex;
  }
  .mobile-nav-menu{
      display: none;
      flex-direction: column;
      top: 0;
      right: 0;
      width: 70%;
      transition: .4s ease-out !important;
      border-radius: 0px 0px 0px 10px;
  }
}

@media screen and (max-width: 580px) {
  .app-logo{
    width: 120px;
  } 
}