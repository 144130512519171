.remove-text-btns{
    display: block;
}

.show-icon-btn{
    display: none;
}

@media screen and (max-width: 480px) {
    .remove-text-btns{
        display: none;
    }
    
    .show-icon-btn{
        display: block;
    }   
}