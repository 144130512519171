.profile-setup{
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 30px 0px;
  border-radius: 3px;
  width: 100%;
}

.profile-setup img{
  width: 150px;
  height: 150px;
}

.profile-setup .profile-followers{
  width: 60% !important;
}

.notifications{
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.profile-btns-wrapper .app-flex-row{
  width: auto !important;
}

.profile-btns{
  min-width: 170px !important;
  height: 46px !important;
  padding: 10px;
  margin-right: 10px;
}

.side-btns{
  min-width: 140px !important;
}

/* width notifications */
  /* Track */
  .notifications::-webkit-scrollbar-track {
    background: #DBDBDB;
  }
  /* Handle */
  .notifications::-webkit-scrollbar-thumb {
    background: var(--lightPurple);
  }
  /* Handle on hover */
  .notifications::-webkit-scrollbar-thumb:hover {
    transition: all 0.4s;
    background: var(--lightPurple);
  }
  
.profile-popup-fields{
  margin-bottom: 40px;
}

/* profile header buttons */
.profile-top-buttons{
  right: 100px;
  bottom: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.profile-top-buttons > button{
  margin-left: 15px;
}

/* profile header buttons end */

@media screen and (max-width: 767px){
  .profile-btns-wrapper .app-flex-row{
    width: 100% !important;
    min-width: 100% !important;
  }
  .profile-popup-fields{
    margin-bottom: 20px;
  }
  .profile-btns{
    width: 100% !important;
    height: 50px !important;
    margin-right: 0px !important;
  }
  .side-btns{
    min-width: 100% !important;
  }
  .profile-setup img{
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 991px) {

    .profile-setup{
        margin: 20px 0px 25px 0px;
    }

    .profile-top-buttons{
        right: 20px !important;
        left: 20px !important;
    }

    .profile-top-buttons > button {
        width: 100% !important;
        margin-top: 15px;
        margin-left: 0;
    }

    .profile-setup .profile-followers{
      width: 100% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .profile-setup img{
    width: 105px;
    height: 105px;
  }
  .profile-setup .profile-followers{
    width: 80% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .profile-top-buttons{
    right: 30px;
  }
  .profile-setup img{
    width: 125px;
    height: 125px;
  }
}