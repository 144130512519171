.price-input-fields{
    border-top: none;
    border-left: none;
    border-right: none;
    width: 140px;
}

.price-input-fields:focus, .price-input-fields:active{
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
}

.discover-price-filter{
    margin-left: auto;
}

@media screen and (max-width: 767px) {
    .discover-price-filter{
        margin-right: auto;
        padding: 0 17px;
        margin-top: 20px;
    }   
    .price-input-fields{
        width: 35%;
    }
}