.modal{
    opacity: .99;
    padding: 0px !important;
    border: none !important;
    outline: none !important;
    z-index: 1000;
}

.modal-content{
    border: none !important;
    background: var(--grey-30) !important;
}

.modal-body{
    padding: 0px .3px !important;
    margin: 0;
    border: none !important;
    outline: none !important;
}