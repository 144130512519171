.app-preloader img{
    animation: 
    /* pulse 3s ease infinite alternate,  */
    nudge 2s linear infinite alternate;
}

@keyframes pulse {
    0%, 100% {
    }
    50% {
    }
  }
  
  @keyframes nudge {
    0% {
      transform: translate(0, 0);
    }
    
    50% {
      transform: translate(150px, 0);
    }
    
    100% {
      transform: translate(-150px, 0);
    }
  }
  

  /**
 * ==============================================
 * Dot Falling
 * ==============================================
 */

.app-preloader .dot-falling {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9999px 0 0 0 #9880ff;
    animation: dotFalling 1s infinite linear;
    animation-delay: .1s;
  }
  
  .app-preloader .dot-falling::before, .dot-falling::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .app-preloader .dot-falling::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFallingBefore 1s infinite linear;
    animation-delay: 0s;
  }
  
  .app-preloader .dot-falling::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFallingAfter 1s infinite linear;
    animation-delay: .2s;
  }
  
  @keyframes dotFalling {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 #9880ff;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  
  @keyframes dotFallingBefore {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 #9880ff;
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  
  @keyframes dotFallingAfter {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 #9880ff;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  