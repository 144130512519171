.order-1{
    order: 1;
}

.order-2{
    order: 2;
}

@media screen and (max-width: 520px) {
    .order-1{
        order: 2 !important;
    }
    
    .order-2{
        order: 1 !important;
    }   
}