/* footer */
.footer{
    width: 100%;
    height: auto;
    background-image: url('../../Assets/images/footer-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-overlay{
    background: rgba(0, 0, 0, .3);
}


/* footer-art */

.footer-art{
    width: 75%;
    height: 400px;
    margin: 15px auto 40px auto !important;
    border-radius: 20px;
}

.footer-art span{
    font-size: 40px;
}

.footer-art p{
    font-size: 16px;
    width: 70%;
}

.footer-art button{
    width: 160px;
    padding: 12px 0px !important;
}

.footer-overlay-links{
    width: 70%;
}

@media screen and (max-width: 767px) {
    .footer-art{
        text-align: center;
        padding: 0px 10px;
        width: 86%;
    }

    .footer-art p{
        width: 100%;
    }

    .footer-art span{
        font-size: 25px !important;
    }   

    /* footer */
    .footer .footer-bottom-links{
        width: 100% !important;
        justify-content: center !important;
        margin-top: 10px;
    }
}

@media screen and (max-width: 991px) {
    .footer-overlay-links{
        width: 90% !important;
        padding: 30px 0px;
    }
    .image-links{
        padding-left: 30px !important;
    }
}

/* @media screen and (min-width: 768px) and (max-width: 1199px){

} */