.left-side{
    width: 50%;
    padding: 0;
    margin: 0;
}

.right-side{
    width: 50%;
    position: relative;
}

.img-rounded{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.auth-input-fields{
    border: none;
    border-bottom: 1px solid var(--black);
    padding: 8px 10px 8px 0px;
    margin-top: 20px;
    font-size: 14px;
    opacity: .7;
    border-color: grey !important;
}

.auth-input-fields:focus, .auth-input-fields:active{
    outline: none !important;
}

@media screen and (max-width: 991px) {
    
    .left-side{
        display: none !important;
    }

    .right-side{
        width: 100%;
    }

    .column-adjustment{
        padding: 25px 10px;
    }
}