*,
*::before,
*::after {
  padding: 0;
  margin: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

.mouse-hover:hover{
  transform: scale(1.03);
  transition: all .3s ease-out;
}

html {
  font-size: 100%;
  line-height: 1.4;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

button, button:active, button:focus, input, input:focus, input:active{
  outline: none !important;
}

h1,
p,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

/* fonts added */
@font-face {
  font-family: "PoppinsMedium", 'Poppins', sans-serif;
  src: local('Poppins-Medium'),
    url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsRegular", 'Poppins', sans-serif;
  src: local('Poppins-Regular'),
    url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "GilroyLight";
  font-family: 'Questrial', sans-serif;
  font-family: 'Roboto', sans-serif;
  src: local('Gilroy-Light'), url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;1,100&family=Questrial&family=Roboto:wght@300&display=swap) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: "ClashRegular";
  src: local('ClashDisplay-Regular'),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayRegular.woff) format('woff');
    font-weight: 300 !important;
}

@font-face {
  font-family: "ClashSemibold";
  font-style: normal;
  src: local('ClashDisplay-Semibold'),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayRegular.woff) format('woff');
}

@font-face {
  font-family: "ClashMedium";
  font-style: normal;
  src: local('ClashDisplay-Medium'),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayRegular.woff) format('woff');
    font-weight: 500;
}

@font-face {
  font-family: "ClashBold";
  font-style: normal;
  src: local('ClashDisplay-Bold'),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayRegular.woff) format('woff');
    font-weight: 600;
}

/* width */
::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--lightPurple);
  border-radius: 50px;
  -webkit-border-radius: 50px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 0.4s;
  background: var(--lightPurple);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.explore-btn:hover{
  background: var(--white);
  color: var(--black) !important;
  transition: .3s ease-in-out;  
}

.list-item-ratio{
  width: 95% !important;
}

.list-item-ratio:hover{
  transform: scale(1.05);
  transition: .3s ease-in-out;
}

.nft-hover:hover{
  transform: scale(1.03) !important;
  transition: .3s ease-in-out;
}

.break-lines{
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.hover:hover{
  transform: scale(1.02) !important;
}

.hide-scroll::-webkit-scrollbar{
  display: none;
}

:is(input, textarea):required[value=""] {
  border-color: #F3037F !important;
}

:is(input, textarea):focus {
  outline: 2px solid skyblue !important;
  border: none !important;
}

@media screen and (max-width: 767px){
  .list-item-ratio{
    width: 100%;
  }
}