.header-section {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  padding: 0px inherit !important;
  /* padding: 0em 0em 120em 0em; */
}

.header-section-btns{
  max-width: max-content !important;
  width: auto !important;
}

.header-section-btns .header-btn{
  width: 170px;
}

.header-section-btns .header-btn:nth-child(1){
  margin-right: 18px;
}

.header-buy-button:hover{
  border: 1px solid var(--pink) !important;
  outline: none !important;
  color: var(--white) !important;
  transition: .1s ease-out;
  background: var(--pink) !important;
  font-weight: bold;

  /* linear-gradient(180deg, var(--lightPurple) 0%, var(--lightBlue) 100%) 0% 0%
  no-repeat padding-box !important; */
}

.headerCounts {
  text-align: center;
  justify-content: center !important;
  width: 75%;
}

.headerNft {
  /* width: 75%; */
  z-index: 2;
  object-fit: contain;
}

.bgHeaderRight {
  display: none !important;
}

.heroGrid {
  margin-top: 7em;
}

.translate-row {
  /* transform: translateX(20%); */
  overflow: hidden !important;
}

.header-arts {
  padding: 0px 80px !important;
}

@media screen and (max-width: 1040px) {
  .header-section .header-section-btns{
    min-width: 100%;
  }
}

@media screen and (min-width: 991px) {
  .headerCounts {
    text-align: left;
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 767px) {
  .header-section{
    height: auto !important;
    min-height: auto !important;
    padding-top: 120px !important;
    padding-bottom: 30px !important;
  }
  .headerCounts{
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 420px){
  .header-section-btns .header-btn{
    width: 100%;
  }
  
  .header-section-btns .header-btn:nth-child(1){
    margin-right: 0px;
  }
}