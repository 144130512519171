.font-42px {
  font-size: 42px !important;
}

.font-36px {
  font-size: 36px !important;
}

.font-30px {
  font-size: 30px !important;
}

.font-28px {
  font-size: 28px !important;
}

.font-25px {
  font-size: 25px !important;
}

.font-23px {
  font-size: 23px !important;
}

.font-20px {
  font-size: 20px !important;
}

.font-18px {
  font-size: 18px !important;
}

.font-16px {
  font-size: 16px !important;
}

.font-15px {
  font-size: 15px !important;
}

.font-13px {
  font-size: 13px !important;
}

.font-10px {
  font-size: 10px !important;
}

.font-14px {
  font-size: 14px !important;
}

.app-flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
}

.app-flex-column {
  display: flex !important;
  flex-direction: column !important;
  margin: 0;
}

.cursor-pointer-sort {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none !important;
}

.bg-button {
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px 22px !important;
  border-radius: 2px;
  border: none !important;
}

.bg-button-purple {
  background: var(--lightPurple) !important;
  color: var(--white) !important;
}
.bg-button-white {
  background: var(--white) !important;
  color: var(--black) !important;
}

.gradient {
  background: transparent
    linear-gradient(180deg, var(--lightPurple) 0%, var(--lightBlue) 100%) 0% 0%
    no-repeat padding-box !important;
  opacity: 1;
}

.text-underline {
  text-decoration: underline !important;
}

.container-shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
}

.border-white {
  border: 1px solid var(--white) !important;
}

.font-normal {
  font-weight: normal;
}
.font-medium {
  font-weight: 500;
}
.normal-btn {
  width: 100px;
  height: 42px;
}

.w-90 {
  width: 90%;
}

.padding-left-right {
  padding: 0px 50px;
}

.width-75px {
  width: 75px !important;
}

/* fonts */
.font-playfair {
  font-family: "Playfair Display ", serif;
}
.font-poppinsMedium {
  font-family: "PoppinsMedium";
}
.font-poppinsRegular {
  font-family: "PoppinsRegular";
}
.font-gilroy {
  font-family: "GilroyLight";
}
.font-clashRegular {
  font-family: "ClashRegular";
}
.font-clashSemibold {
  font-family: "ClashSemibold";
}
.font-clashMedium {
  font-family: "ClashMedium";
}
.font-clashBold {
  font-family: "ClashBold";
}
.font-normal {
  font-weight: normal !important;
}

input[type="radio"],
input[type="checkbox"] {
  border: 0px;
  width: 20px;
  height: 20px;
  background: var(--lightPurple);
}

video,
audio {
  object-fit: contain !important;
}

/* dropdowns */

#dropdown-button-drop-0{
  color: inherit !important;  
}

#dropdown-item-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  color: inherit !important;
}

#dropdown-item-button::after {
  margin-top: 10px !important;
  outline: none !important;
  box-shadow: none !important;
  /* border: none !important; */
}

#dropdown-item-button:active, #dropdown-item-button:focus{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.dropdown-menu {
  border: none !important;
  outline: none !important;
  width: 100% !important;
  padding: 0px !important;
  background: whitesmoke !important;
  max-height: 240px;
  min-width: 160px !important;
  overflow-y: auto;
}

.menu-width{
  min-width: 260px !important;
}

.cc-w-75pr {
  width: 75%;
}

/* common header height */
.header-height-lg {
  height: 550px;
}

.header-height-md {
  height: 340px !important;
}

.header-height-sm {
  height: 340px !important;
}

/* flex row adjustment */
.adjustment-end {
  justify-content: end;
}

/* collection detail */
.rounded-layer-img {
  width: 150px;
  height: 150px;
}

.letter-spacing-normal {
  letter-spacing: 1px !important;
}

@media screen and (max-width: 380px){
  .artwork-height {
    height: 250px !important;
    max-height: 250px !important;
  }
}

@media screen and (max-width: 767px) {
  .adjustment-end {
    justify-content: start !important;
  }

  .header-height-lg {
    height: auto;
    padding: 100px 40px !important;
  }

  .header-height-md {
    height: 350px !important;
  }

  .header-height-sm {
    height: 200px !important;
  }

  .cc-w-75pr {
    width: 100%;
  }

  .artwork-height {
    height: 350px !important;
    max-height: 350px !important;
  }

  .padding-left-right {
    padding: 0px 0px;
  }

  .w-90 {
    width: 100%;
  }

  .margin-adjustment {
    margin-top: 30px !important;
  }

  .font-42px {
    font-size: 34px !important;
  }

  .font-36px {
    font-size: 30px !important;
  }

  .font-30px {
    font-size: 25px !important;
  }

  .font-28px {
    font-size: 22px !important;
  }

  .font-25px {
    font-size: 20px !important;
  }

  .font-23px {
    font-size: 18px !important;
  }

  .font-20px {
    font-size: 17px !important;
  }

  .font-18px {
    font-size: 16px !important;
  }

  .font-16px {
    font-size: 15px !important;
  }

  .font-15px {
    font-size: 14px !important;
  }

  .font-13px {
    font-size: 12px !important;
  }

  .font-14px {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 480px) {
  .font-42px {
    font-size: 25px !important;
  }
  .font-36px {
    font-size: 20px !important;
  }
  .font-30px {
    font-size: 20px !important;
  }
  .rounded-layer-img {
    width: 100px;
    height: 100px;
  }
  .display-none {
    display: none;
  }
  .font-42px {
    font-size: 23px !important;
  }
  .artwork-height {
    height: 300px !important;
    max-height: 300px !important;
  }
}
